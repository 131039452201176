import React from 'react';
import classes from './styles.module.css';
import { HOME_PAGE } from '../../constants/route.const';
import { Image, Box, Heading, Flex } from '@chakra-ui/react';
import SKButton from '../UI/SKButton/index';
import logo from '@/public/assets/rupiyo-sm.svg?url';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { StorageService } from "@/service/Storage";

function Header() {
  const router = useRouter();
  const pathname = router.pathname;
  const isHome = pathname === '/';
  const showBg =
    pathname === '/profile' || pathname === '/verify' || pathname === '/';

  const hasAccessToken = StorageService.getAccessToken() ? true : false;

  return (
    <Flex as="header" className={classes.header} justifyContent={'space-between'}>
      <Link href={HOME_PAGE}>
        <Box className={classes.left}>
          <Flex alignItems={'center'}>
            <Image
              className={classes.logo}
              src={logo}
              alt="rupiyo logo"
              h={['35px', '45px']}
              w={['40px', '50px']}
            />
            <Heading
              fontSize={['21px', '28px']}
              display={['none', null, 'block']}
              color={['#8056B2']}
              fontWeight={'semibold'}
            >
              Rupiyo
            </Heading>
          </Flex>
        </Box>
      </Link>
      {isHome && hasAccessToken && (
        <Link href={"/profile"}>
          <SKButton
            w={['130px', '200px']}
            h={['45px', '50px']}
            mr={'23px'}
            bgGradient="linear(to-r, #8056B2, #9D88B2)"
            color="white"
            borderRadius="13px"
            fontWeight="semibold"
          >
            Wallet
          </SKButton>
        </Link>
      )}
    </Flex>
  );
}
export default Header;
