import Footer from '../Footer';
import Header from '../Header';
import classes from './styles.module.css';
import BGCircle from '@/public/assets/bgCircle.svg?url';
import { Box, useMediaQuery, Flex, Image } from '@chakra-ui/react';
import { useRouter } from 'next/router';

function Layout({ headerProps, children, hideFooter }) {
  const [isMediumW] = useMediaQuery('(min-width:420px) and (max-width:490px)');
  const router = useRouter();
  const pathname = router.pathname;
  const showBg =
    pathname === '/profile' || pathname === '/verify' || pathname === '/';
  return (
    <Box className={classes.Layout} position={['relative']} overflow="hidden">
      <Flex
        position="absolute"
        zIndex="-1"
        top={'0px'}
        minHeight={['100vh', '80vh']}
        width={'100%'}
      >
        <Image
          height={['90vmax', null, '70vw']}
          src={BGCircle}
          position="absolute"
          top={['85vh', null, '90vh']}
          left={['-50vw', null, '-16vw']}
          alt="circle"
          zIndex="1"
          opacity={0.5}
        />
      </Flex>
      <div className={classes.Header}>
        <Header {...headerProps} />
      </div>
      <main>{children}</main>
      {hideFooter ? null : (
        <div className={classes.Footer}>
          <Footer />
        </div>
      )}
    </Box>
  );
}
export default Layout;
