import React from 'react';
import classes from './styles.module.css';
import {
  PRIVACY_POLICY_PAGE,
  TERMS_AND_CONDITIONS_PAGE,
  FAQS_PAGE,
} from '../../constants/route.const';
import { Box, Flex, Heading, Image } from '@chakra-ui/react';
import Link from 'next/link';


function Footer() {
  return (
    <Flex as="footer" pr={[0, 0]} className={classes.footer}>
      <div className={classes.links}>
        <Link href={PRIVACY_POLICY_PAGE} className={classes.link} target="_blank">
          Privacy Policy
        </Link>
        <span>|</span>
        <Link
          href={TERMS_AND_CONDITIONS_PAGE}
          className={classes.link}
          target="_blank"
        >
          Terms & Conditions
        </Link>
        <span>|</span>
        <Link href={FAQS_PAGE} className={classes.link}>
          FAQ
        </Link>
      </div>
      <Box className={classes.copyright} opacity={0.5} mt={[4, 0]}>
        Copyright ©{new Date().getFullYear()} rupiyo.app
      </Box>
    </Flex>
  );
}
export default Footer;
