import React from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { useToggle } from '../../hooks/useToggle';
import ExpandMoreIcon from '@/public/assets/icons/expand_more.svg';
import ExpandLessIcon from '@/public/assets/icons/expand_less.svg';

function ToggleRow({
  header,
  body,
  defaultOpen,
  noIcon,
  classes = {},
  variant = 'add', //variant add | expand
}) {
  const [collapsed, toggle] = useToggle(defaultOpen);
  const MoreIcon = ExpandMoreIcon;
  const LessIcon = ExpandLessIcon;
  return (
    <div
      className={clsx(
        styles.container,
        collapsed && styles.selected,
        classes.root && classes.root
      )}
    >
      <div
        className={clsx(styles.header, !body && styles.noCP, styles.iconOnEnd)}
        onClick={toggle}
      >
        {header}
        <>
          {!collapsed ? (
            <MoreIcon
              size="small"
              onClick={toggle}
              className={clsx(styles.icon, collapsed)}
            />
          ) : (
            <LessIcon
              size="small"
              onClick={toggle}
              className={clsx(styles.icon, styles.active)}
            />
          )}
        </>
      </div>
      {collapsed && (
        <div className={clsx(styles.body, styles.fullBody)}>{body}</div>
      )}
    </div>
  );
}

export default ToggleRow;
